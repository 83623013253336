






































































































































































































import { Apiary, Hive, Post } from '@/types';
import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component
export default class NewUpdateDialog extends Vue {
  dialog = false
  loading = false

  valid = false

  selectedApiaries: string[] = [];
  selectedHives: string[] = [];

  subject = ''
  message = ''

  // Quando è true, mostra un'anteprima non modificabile del post
  preview = false

  editor = ClassicEditor
  editorConfig = {
    removePlugins: ['Heading'],
    toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'link'],
    shouldNotGroupWhenFull: true,
  }
  editorVisible = false

  imageName: string | null = null
  imageUrl: string | null = null

  mounted() {
    console.log(`${this.constructor.name}: mounted()`); // eslint-disable-line no-console
  }

  get value(): boolean {
    return this.$store.getters.newUpdateDialog;
  }

  get updateInitialApiary(): string | null {
    return this.$store.getters.updateInitialApiary;
  }

  get updateInitialHive(): string | null {
    return this.$store.getters.updateInitialHive;
  }

  get apiaries(): Apiary[] {
    return this.$store.getters.apiaries
      .filter((a: Apiary) => a.enabled !== false);
  }

  get apiaryHives(): Hive[] {
    return this.$store.getters.apiaryHives;
  }

  getForm(): (Vue & { validate: () => boolean; reset: () => boolean }) | undefined {
    return this.$refs.form as Vue & {
      validate: () => boolean;
      reset: () => boolean;
    };
  }

  scrollToTop() {
    // Scrolla in cima alla finestra
    this.$nextTick(() => {
      const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
      if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
    });
  }

  unselectApiary(apiary: Apiary) {
    console.log(`${this.constructor.name}: unselectApiary() apiary =`, apiary); // eslint-disable-line no-console

    const newSelectedApiaries = this.selectedApiaries.filter((apiaryId) => apiaryId !== apiary.id);

    this.selectedApiaries = newSelectedApiaries;
  }

  unselectHive(hive: Hive) {
    console.log(`${this.constructor.name}: unselectHive() hive =`, hive); // eslint-disable-line no-console

    const newSelectedHives = this.selectedHives.filter((hiveId) => hiveId !== hive.id);

    this.selectedHives = newSelectedHives;
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    console.log(`${this.constructor.name}: onValueChanged()`); // eslint-disable-line no-console

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    console.log(`${this.constructor.name}: onDialogChanged() val =`, val); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'newUpdate');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.selectedApiaries = [];
      this.subject = '';
      this.message = '';
      this.preview = false;

      const form = this.getForm();
      if (form) form.reset();

      // Preseleziona l'apiario mostrato
      this.selectedApiaries = this.updateInitialApiary !== null ? [this.updateInitialApiary] : [];

      // Preseleziona l'alveare mostrato
      this.selectedHives = this.updateInitialHive !== null ? [this.updateInitialHive] : [];

      // Scrolla in cima alla finestra
      this.scrollToTop();

      // Mostra l'editor dopo un po' di tempo per evitare che il toolbar
      // non venga mostrato correttamente.
      setTimeout(() => {
        this.editorVisible = true;
      }, 500);
    } else {
      // Dialog chiust

      // Nasconde l'editor
      this.editorVisible = false;
    }
  }

  async onFileInputChange(file: File | null = null) {
    console.log(`${this.constructor.name}: onFileInputChange() file =`, file); // eslint-disable-line no-console

    if (file) {
      // Carica il file
      this.loading = true;
      const imageName = await this.$store.dispatch('uploadImage', {
        image: file,
        bucket: this.$firebasePostsImagesBucket,
      });
      console.log(`${this.constructor.name}: onFileInputChange() imageName =`, imageName); // eslint-disable-line no-console
      this.imageName = imageName;
      this.loading = false;
    } else if (this.imageName) {
      // Rimuove il file caricato precedentemente
      this.loading = true;
      await this.$store.dispatch('deleteImage', {
        imageName: this.imageName,
        bucket: this.$firebasePostsImagesBucket,
      });
      this.imageName = null;
      this.loading = false;
    }
  }

  onCancel() {
    // Svuota gli apieri e arnie iniziali
    this.$store.dispatch('setUpdateInitialHive', null);
    this.$store.dispatch('setUpdateInitialApiary', null);

    this.dialog = false;
  }

  async onPreview() {
    console.log(`${this.constructor.name}: onPreview()`); // eslint-disable-line no-console

    // Se stava mostrando l'anteprima...
    if (this.preview) {
      // ... torna alla modifica.
      this.preview = false;
      this.scrollToTop();
      return;
    }

    // Consente l'attivazione dell'anteprima soltanto
    // se sono stati inseriti tutte le informazioni richieste.
    const form = this.getForm();
    if (form?.validate()) {
      this.imageUrl = await this.$store.dispatch('getImageUrl', {
        imageName: this.imageName,
        bucket: this.$firebasePostsImagesBucket,
      });
      this.preview = true;

      this.scrollToTop();
    }
  }

  async onPublish() {
    console.log(`${this.constructor.name}: onPublish()`); // eslint-disable-line no-console

    const form = this.getForm();

    if (form?.validate()) {
      this.loading = true;

      const post: Post = {
        status: 'pending-review',

        apiaries: this.selectedApiaries,
        hives: this.selectedHives,

        subject: this.subject,
        message: this.message,

        imageName: this.imageName,
      };

      // Crea il post
      await this.$store.dispatch('addPost', {
        post,
        bucket: this.$firebasePostsImagesBucket,
      });

      // Svuota gli apieri e arnie iniziali
      this.$store.dispatch('setUpdateInitialHive', null);
      this.$store.dispatch('setUpdateInitialApiary', null);

      this.dialog = false;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
}
